<template>
<div class="wrap">
    <div class="mg-auto" style="width: 1250px;">
        <div class="con_top">
            <h2 class="mb-20"><span>■</span> 센터수정</h2>
            <input type="hidden" name="_token" id="_token" value="iu9xcVuFZ79uA3zQNaVTassFPxCio38exMLm1RxR">
            <input type="hidden" name="center_id" id="center_id" value="1">
            <input type="hidden" name="role_id" id="role_id" value="1"><!-- 상담사 -->
            <input type="hidden" name="callback" id="callback" value="">

            <div class="con">
                <div class="con_table">
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-120px">아이디 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" disabled v-model="id" class="ml-20 w-200px">
                                </td>
                                <th>비밀번호 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="pwd" class="ml-20 w-200px"><br>
                                    <span class="ml-20"> 영문 대/소문자 + 숫자 + 특수문자 포함 6~20자</span>
                                </td>
                            </tr>
                            <tr>
                                <th class="w-120px">센터명 <span class="red">*</span></th>
                                <td class="left" colspan="3">
                                    <input type="text" v-model="name" @change="isValidName = false" class="ml-20 w-200px">
                                    <button type="button" class="default-btn ml-20" @click="checkName()">중복체크</button>
                                </td>
                                <!-- <th>코드명</th>
                                <td class="left">
                                    <input type="text" class="ml-20 w-200px">
                                </td> -->
                            </tr>
                            <tr>
                                <th>주소 <span class="red">*</span></th>
                                <td class="left" colspan="3">
                                    <input type="text" v-model="post" disabled class="pl-20 ml-20 w-200px">
                                    <button type="button" class="default-btn ml-20 mb-10" @click="onModal()">주소 검색</button> <br>
                                    <input type="text" v-model="address" disabled class="pl-20 ml-20 mb-10 mr-10 w-400px">기본주소 <br>
                                    <input type="text" v-model="detailAddress" class="pl-20 ml-20 mr-10 w-400px">상세주소
                                </td>
                            </tr>
                            <tr>
                                <th>대표번호 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" class="ml-20" style="width:87px;" v-model="mainNumber1" id="mainNumber1" name="mainNumber1"> -
                                    <input type="text" style="width:100px;" v-model="mainNumber2" id="mainNumber2" name="mainNumber2" value=""> -
                                    <input type="text" style="width:100px;" v-model="mainNumber3" id="mainNumber3" name="mainNumber3" value="">
                                </td>
                                <th>센터장 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="centerCap" class="ml-20 w-200px">
                                </td>
                            </tr>
                            <tr>
                                <th>휴대폰번호 <span class="red">*</span></th>
                                <td class="left">
                                    <select class="ml-20" v-model="phone1" id="phone1" name="phone1">
                                        <option value="010">010</option>
                                        <option value="011">011</option>
                                        <option value="016">016</option>
                                        <option value="017">017</option>
                                        <option value="018">018</option>
                                        <option value="019">019</option>
                                    </select> -
                                    <input type="text" style="width:100px;" v-model="phone2" id="phone2" name="phone2" value=""> -
                                    <input type="text" style="width:100px;" v-model="phone3" id="phone3" name="phone3" value="">
                                </td>
                                <th>상담실</th>
                                <td class="left">
                                    <input type="text" v-model="consRoom" class="ml-20 w-60px"> 개
                                </td>
                            </tr>
                            <tr>
                                <th>놀이치료실</th>
                                <td class="left">
                                    <input type="text" v-model="playRoom" class="ml-20 w-60px"> 개
                                </td>
                                <th>정산유형</th>
                                <td class="left">
                                    <select v-model="purType" class="ml-20 w-100px">
                                        <option value="T">과세</option>
                                        <option value="F">면세</option>
                                        <option value="D">소득공제 3.3%</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>상담료</th>
                                <td class="left">
                                    <input type="text" v-model="consPrice" class="ml-20 w-100px"> 원
                                </td>
                                <th>진행현황</th>
                                <td class="left">
                                    <select v-model="contractEndYn" class="ml-20 w-100px">
                                        <option value="진행">진행</option>
                                        <option value="종료">종료</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>급여지급 <span class="red">*</span></th>
                                    <td class="left">
                                        <select class="ml-20" v-model="salaryType">
                                            <option value="개인">개인</option>
                                            <option value="계산서">계산서</option>
                                        </select> 
                                    </td>
                                <th>홈페이지</th>
                                <td class="left" colspan="3">
                                    <input type="text" v-model="homepage" class="ml-20 w-300px">
                                </td>
                            </tr>
                            <tr>
                                <th>상담서비스</th>
                                <td class="left" colspan="3">
                                    <input type="checkbox" v-model="consService.adult.use" class="ml-20"> 성인
                                    <input type="checkbox" v-model="consService.child.use" class="ml-20"> 아동
                                    <input type="checkbox" v-model="consService.teen.use" class="ml-20"> 청소년
                                    <input type="checkbox" v-model="consService.family.use" class="ml-20"> 가족
                                    <input type="checkbox" v-model="consService.couple.use" class="ml-20"> 부부
                                    <input type="checkbox" v-model="consService.etc.use" class="ml-20"> 기타
                                </td>
                            </tr>
                            <tr>
                                <th>센터사진</th>
                                <td class="left">
                                    <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.centerFile.click()">파일 선택</button>
                                    <input v-show="false" ref="centerFile" type="file" @change="uploadCenterPhoto($event)" /><br>
                                    <span v-for="(item, index) of centerFiles" :key="index" class="ml-20" style="cursor:pointer">{{item.name}} <span class="underline pointer" @click="removePhotoFile(item.name, index)">삭제</span><br></span>

                                </td>
                                <th>사업자등록증</th>
                                <td class="left">
                                    <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.registrationFile.click()">파일 선택</button>
                                    <input v-show="false" ref="registrationFile" type="file" @change="uploadFile($event, 'registrationFile')" /><br>
                                    <span v-if="centerRegName !== ''" class="ml-20" style="cursor:pointer">{{centerRegName}} <span class="underline pointer" @click="removeRegFile()">삭제</span></span>
                                </td>
                            </tr>
                            <tr>
                                <th>통장사본</th>
                                <td class="left" colspan="3">
                                    <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.bankbookFile.click()">파일 선택</button>
                                    <input v-show="false" ref="bankbookFile" type="file" @change="uploadFile($event, 'bankbookFile')" /><br>
                                    <span v-if="centerBankName !== ''" class="ml-20" style="cursor:pointer">{{centerBankName}} <span class="underline pointer" @click="removeBankFile()">삭제</span></span>
                                </td>
                            </tr>                            
                        </tbody>
                    </table>
                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                        <a class="btn_cancle mr-10 pointer" @click="putCenterInfo()">수정</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <address-modal :isOnModal="isOnModal" @close="closeModal"></address-modal>
</div>
</template>

<script>
import {
    IDFormatCheck,
    PWFormatCheck,
    EmptyCheck,
} from '@/utils/validation.js'
import AddressModal from '@/components/modal/searchAddress.vue'
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    components: {
        AddressModal: AddressModal,
    },
    data: () => ({
        isOnModal: false, // 주소 모달 on/off 여부

        post: '', // 주소코드
        defaultAddress: '', // 기본주소
        detailAddress: '', // 상세주소

        centerFile: [], //  센터사진 파일
        centerFiles: [], //  센터사진 파일
        registrationFile: '', //  사업자등록증 파일
        bankbookFile: '', // 통장사본 파일

        address: '',
        centerBankName: '',
        centerBankUrl: '',
        centerCap: '',
        centerPhotoName1: '',
        centerPhotoUrl1: '',
        centerPhotoName2: '',
        centerPhotoUrl2: '',
        centerPhotoName3: '',
        centerPhotoUrl3: '',
        centerRegName: '',
        centerRegUrl: '',
        consPrice: 0,
        consRoom: 0,
        consService: { // 상담서비스
            adult: {
                name: '성인',
                use: false
            },
            child: {
                name: '아동',
                use: false
            },
            teen: {
                name: '청소년',
                use: false
            },
            family: {
                name: '가족',
                use: false
            },
            couple: {
                name: '부부',
                use: false
            },
            etc: {
                name: '기타',
                use: false
            },
        },
        detailAddress: '',
        homepage: '',
        id: '',
        idx: -1,
        mainNumber1: '', // 대표번호
        mainNumber2: '', // 대표번호
        mainNumber3: '', // 대표번호
        name: '',
        permission: '',
        phone1: '010',
        phone2: '',
        phone3: '',
        playRoom: 0,
        post: '',
        purType: '',
        pwd: '',

        permission: '', // 허가 여부
        isValidName: true, // 중복 여부 false 일때 중복체크 안된상태
        contractEndYn: '진행',
        salaryType: '' // 급여지급
    }),

    updated() {
        if (this.isOnModal) {
            this.$nextTick(function () {
                // 주소 찾기 결과 값 로드
                // this.$EventBus.$on('close', (x) => {
                //     this.addressVisible = x
                // })
                this.$EventBus.$on('closeSearchZipCode', data => {
                    if (data) {
                        this.defaultAddress = data.address
                        this.post = data.zonecode
                        console.log('data', data)
                        this.isOnModal = false

                        this.$EventBus.$off('close')
                        this.$EventBus.$off('closeSearchZipCode')
                    }
                })
            })
        }
    },

    mounted() {
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getCenter(this.idx)
    },

    methods: {
        // 사업자등록증 삭제
        removeRegFile() {
            this.registrationFile = ''
            this.centerRegName = ''
            this.centerRegUrl = ''
        },

        // 통장사본 삭제
        removeBankFile() {
            this.bankbookFile = ''
            this.centerBankName = ''
            this.centerBankUrl = ''
        },

        // 센터사진 삭제
        removePhotoFile(name, index) {
            console.log('index', index)
            for (var [idx, file] of this.centerFile.entries()) {
                if (file.name === name) {
                    this.centerFile.splice(idx, 1)
                }
            }

            var check = false
            for (var [idx, cFile] of this.centerFiles.entries()) {
                for (var i = 1; i <= 3; i++) {
                    if (cFile.name === this['centerPhotoName' + i.toString()]) {
                        this['centerPhotoName' + (i).toString()] = ''
                        this['centerPhotoUrl' + (i).toString()] = ''
                        check = true
                        break
                    }
                }
                if (check) {
                    break
                }
            }

            this.centerFiles.splice(index, 1)
        },

        // 중복체크 api
        checkName() {
            var params = {
                name: this.name
            }
            this.axios.get('/api/v1/center/dup', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        alert('사용 가능한 센터명입니다.')
                        this.isValidName = true
                    } else {
                        alert('같은 이름으로 등록된 센터가 있습니다.')
                    }
                })
                .catch(err => {
                    console.log(err)
                    alert(err)
                })
        },
        // 센터 정보 api 호출
        getCenter(idx) {
            var params = {
                idx: idx
            }

            this.axios.get(`/api/v1/center/${idx}`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.center = res.data.center
                        //this.contractEndYn = res.data.center.contractEndYn

                        for (var item of Object.keys(res.data.center)) {
                            if (typeof this[item] !== 'undefined') {
                                if (item === 'centerFile' || item === 'centerBankName' || item === 'centerRegName' ||
                                    item === 'centerPhotoName1' || item === 'centerPhotoName2' || item === 'centerPhotoName3') {
                                    continue
                                }

                                // 상담 서비스 매핑 string => object
                                if (item === 'consService') {
                                    if (res.data.center[item] !== '') {
                                        var consService = res.data.center[item].split('/')
                                        for (var name of consService) {
                                            for (var key of Object.keys(this.consService)) {
                                                if (name === this.consService[key].name) {
                                                    this.consService[key].use = true
                                                }
                                            }
                                        }
                                    }
                                    continue
                                }

                                // 통장사본 있는 경우 값 할당
                                if (item === 'centerBankUrl') {
                                    if (res.data.center.centerBankUrl && res.data.center.centerBankUrl.includes('object.ncloudstorage.com')) {
                                        this.centerBankUrl = res.data.center.centerBankUrl
                                        this.centerBankName = res.data.center.centerBankName
                                    }
                                    continue
                                }

                                // 센터사진 있는 경우 값 할당
                                if (item === 'centerPhotoUrl1') {
                                    if (res.data.center.centerPhotoUrl1 && res.data.center.centerPhotoUrl1.includes('object.ncloudstorage.com')) {
                                        this.centerPhotoUrl1 = res.data.center.centerPhotoUrl1
                                        this.centerPhotoName1 = res.data.center.centerPhotoName1

                                        this.centerFiles.push({
                                            name: this.centerPhotoName1,
                                            url: this.centerPhotoUrl1
                                        })
                                    }
                                    continue
                                }

                                // 센터사진 있는 경우 값 할당
                                if (item === 'centerPhotoUrl2') {
                                    if (res.data.center.centerPhotoUrl2 && res.data.center.centerPhotoUrl2.includes('object.ncloudstorage.com')) {
                                        this.centerPhotoUrl2 = res.data.center.centerPhotoUrl2
                                        this.centerPhotoName2 = res.data.center.centerPhotoName2

                                        this.centerFiles.push({
                                            name: this.centerPhotoName2,
                                            url: this.centerPhotoUrl2
                                        })
                                    }
                                    continue
                                }

                                // 센터사진 있는 경우 값 할당
                                if (item === 'centerPhotoUrl3') {
                                    if (res.data.center.centerPhotoUrl3 && res.data.center.centerPhotoUrl3.includes('object.ncloudstorage.com')) {
                                        this.centerPhotoUrl3 = res.data.center.centerPhotoUrl3
                                        this.centerPhotoName3 = res.data.center.centerPhotoName3

                                        this.centerFiles.push({
                                            name: this.centerPhotoName3,
                                            url: this.centerPhotoUrl3
                                        })
                                    }
                                    continue
                                }

                                // 사업자등록증 있는 경우 값 할당
                                if (item === 'centerRegUrl') {
                                    if (res.data.center.centerRegUrl && res.data.center.centerRegUrl.includes('object.ncloudstorage.com')) {
                                        this.centerRegUrl = res.data.center.centerRegUrl
                                        this.centerRegName = res.data.center.centerRegName
                                    }
                                    continue
                                }

                                this[item] = res.data.center[item]

                                if (res.data.center.purType === null) {
                                    this.purType = 'T'
                                }
                            }

                            // 대표번호 번호 매핑
                            if (res.data.center.mainNumber !== null) {
                                var mainNumber = res.data.center.mainNumber.split('-')

                                if (mainNumber.length === 3) {
                                    this.mainNumber1 = mainNumber[0]
                                    this.mainNumber2 = mainNumber[1]
                                    this.mainNumber3 = mainNumber[2]
                                }
                            }

                            // 휴대폰 번호 매핑
                            if (res.data.center.phone !== null) {
                                var phone = res.data.center.phone.split('-')
                                if (phone.length === 3) {
                                    this.phone1 = phone[0]
                                    this.phone2 = phone[1]
                                    this.phone3 = phone[2]
                                }
                                continue
                            }

                        }

                        this.id = res.data.id
                        this.pwd = res.data.pwd
                    }
                })
                .catch(err => {
                    alert(err)
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 주소검색 모달 on
        onModal() {
            this.isOnModal = true
        },

        // 주소검색 모달 off
        closeModal() {
            this.isOnModal = false
        },

        // 센터 파일 업로드 (최대 3개까지)
        uploadCenterPhoto(event) {
            if (this.centerFiles.length >= 3) {
                alert('센터사진은 최대 3개까지 업로드 가능합니다.')
                return false
            } else {
                /* this.centerFile.push(event.target.files[0])
                this.centerFiles.push({
                    name: event.target.files[0].name,
                    url: ''
                }) */
                // 포맷 검사
                var allowFormat = ['jpg', 'jpeg', 'png', 'gif', 'JPG', 'JPEG', 'PNG', 'GIF']
                var checked = false
                for (var item of allowFormat) {
                    if (event.target.files[0].name.includes(item)) {
                        checked = true
                        break
                    } else {
                        continue
                    }
                }
                if (checked) {
                    // 용량 검사
                    if (event.target.files[0].size > 1048576 * 2) {
                        alert('2MB 이하만 업로드 가능합니다.')
                        return false
                    }

                    this.centerFile.push(event.target.files[0])
                    this.centerFiles.push({
                        name: event.target.files[0].name,
                        url: ''
                    })

                } else {
                    alert('파일 포맷은 jpg, jpeg, png, gif형식만 가능합니다.')
                    return false
                }
            }
        },

        // 파일 업로드
        uploadFile(event, fileType) {
            // 포맷 검사
            var allowFormat = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'PDF', 'JPG', 'JPEG', 'PNG', 'GIF']
            var checked = false
            for (var item of allowFormat) {
                if (event.target.files[0].name.includes(item)) {
                    checked = true
                    break
                } else {
                    continue
                }
            }

            if (checked) {
                // 용량 검사
                if (event.target.files[0].size > 1048576 * 2) {
                    alert('2MB 이하만 업로드 가능합니다.')
                    this.$refs[fileType].value = ''
                    return false
                }

                this[fileType] = event.target.files[0]

                if (fileType === 'centerFile') {
                    this.centerPhotoName = this[fileType].name
                } else if (fileType === 'registrationFile') {
                    this.centerRegName = this[fileType].name
                } else if (fileType === 'bankbookFile') {
                    this.centerBankName = this[fileType].name
                }

                this.$refs[fileType].value = ''
            } else {
                alert('파일 포맷은 pdf, jpg, jpeg, png, gif형식만 가능합니다.')
                this.$refs[fileType].value = ''
                return false
            }
        },

        // 수정
        putCenterInfo() {

            // 비밀번호 검사
            if (!EmptyCheck(this.pwd, '암호를')) {
                return false
            } else {
                if (!PWFormatCheck(this.pwd)) {
                    return false
                }
            }

            if (!EmptyCheck(this.post, '주소를')) {
                return false
            }

            // 대표 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.mainNumber1 !== '' && this.mainNumber2 !== '' && this.mainNumber3 !== '') {
                var mainNumber = this.mainNumber1 + '-' + this.mainNumber2 + '-' + this.mainNumber3
            } else {
                var mainNumber = ''
            }

            if (mainNumber === '') {
                alert('대표번호를 입력해주세요')
                return false
            }

            if (!EmptyCheck(this.centerCap, '센터장을')) {
                return false
            }

            // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.phone2 !== '' && this.phone3 !== '') {
                var phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                var phone = ''
            }

            if (phone === '') {
                alert('핸드폰정보를 입력해주세요')
                return false
            }

            var params = new FormData()
            params.append('id', this.id)
            params.append('pwd', this.pwd)
            params.append('name', this.name)
            params.append('post', this.post)
            params.append('address', this.address)
            params.append('detailAddress', this.detailAddress)
            params.append('mainNumber', mainNumber)
            params.append('centerCap', this.centerCap)
            params.append('phone', phone)
            params.append('consRoom', this.consRoom)
            params.append('playRoom', this.playRoom)
            params.append('purType', this.purType)
            params.append('consPrice', this.consPrice)
            params.append('homepage', this.homepage)
            params.append('permission', this.permission)
            params.append('contractEndYn', this.contractEndYn)
            params.append('salaryType', this.salaryType)
            //params.append('idx', this.idx)

            // 상담서비스 checkbox => string 매핑작업
            var consService = ''
            for (var key of Object.keys(this.consService)) {
                if (this.consService[key].use) {
                    if (consService === '') {
                        consService += this.consService[key].name
                    } else {
                        consService += '/' + this.consService[key].name
                    }

                }
            }

            params.append('consService', consService)

            // 각 파일 빈 binary로 추가해놓기
            params.append('centerFile1', new Blob())
            params.append('centerFile2', new Blob())
            params.append('centerFile3', new Blob())
            params.append('regFile', new Blob())
            params.append('bankFile', new Blob())

            params.append('centerPhotoUrl1', this.centerPhotoUrl1)
            params.append('centerPhotoName1', this.centerPhotoName1)
            params.append('centerPhotoUrl2', this.centerPhotoUrl2)
            params.append('centerPhotoName2', this.centerPhotoName2)
            params.append('centerPhotoUrl3', this.centerPhotoUrl3)
            params.append('centerPhotoName3', this.centerPhotoName3)

            var checkCenterFileIdx = []

            for (var [index, file] of this.centerFile.entries()) {
                for (var i = 0; i < 3; i++) {
                    if (this['centerPhotoUrl' + (i + 1).toString()] === '' && !checkCenterFileIdx.includes(i)) {
                        console.log('들어오긴함?')
                        params.delete('centerFile' + (i + 1))
                        params.append('centerFile' + (i + 1), file)
                        checkCenterFileIdx.push(i)
                        break
                    } else {
                        params.delete('centerPhotoUrl' + (i + 1), this['centerPhotoUrl' + (i + 1).toString()])
                        params.delete('centerPhotoName' + (i + 1), this['centerPhotoName' + (i + 1).toString()])
                        params.append('centerPhotoUrl' + (i + 1), this['centerPhotoUrl' + (i + 1).toString()])
                        params.append('centerPhotoName' + (i + 1), this['centerPhotoName' + (i + 1).toString()])
                    }
                }
            }

            if (this.registrationFile !== '') {
                params.delete('regFile')
                params.append('regFile', this.registrationFile)
            } else {
                params.append('centerRegUrl', this.centerRegUrl)
                params.append('centerRegName', this.centerRegName)
            }
            if (this.bankbookFile !== '') {
                params.delete('bankFile')
                params.append('bankFile', this.bankbookFile)
            } else {
                params.append('centerBankUrl', this.centerBankUrl)
                params.append('centerBankName', this.centerBankName)
            }

            this.axios.put(`/api/v1/center/${this.idx}`, params, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    alert('수정되었습니다.')
                    this.$router.push('/origin/center_manage')
                })
                .catch(err => {
                    console.log(err)
                    alert(err)
                })
        },

        // 이전 페이지로 이동
        cancel() {
            history.back()
        }
    }
}
</script>
